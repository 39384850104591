<template>
  <v-dialog
    v-model="createUniformModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Cadastrar Uniforme
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          class="mx-5 mt-5"
          lazy-validation
          @submit.prevent="createUniform()"
        >
          <v-text-field
            v-model="uniform.name"
            :rules="[rules.required]"
            label="Nome de identificação do uniforme"
            hint="Ex: uniforme 01"
            persistent-hint
            outlined
            required
          />

          <image-uploader
            title="Clique aqui para enviar uma imagem. A imagem deve ser um SVG"
            subtitle="Tamanho recomendado: 200 por 200 pixels"
            :saving="saving"
            :fullWidth="true"
            previewWidth="200px"
            previewHeight="200px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />

          <h2>Elementos do uniforme com cores editáveis</h2>

          <div
            v-for="(el, index) in uniform.elements"
            :key="`uniform-element-${index}`"
            class="element-box mt-6"
          >
            <v-btn
              fab
              x-small
              color="error"
              class="delete-btn"
              @click="removeElement(index)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>

            <v-text-field
              v-model="uniform.elements[index].class"
              :rules="[rules.required]"
              :label="`Classe do elemento ${padWithZeros(index + 1)} *`"
              hint="informe a Classe do elemento encontrado no XML do SVG."
              persistent-hint
              outlined
              required
            />

            <v-text-field
              v-model="uniform.elements[index].name"
              :rules="[rules.required]"
              :label="`Nome do elemento ${padWithZeros(index + 1)} *`"
              hint="O nome do elemento será mostrado para o usuário. Ex: 'Borda'."
              persistent-hint
              outlined
              required
            />

            <v-text-field
              v-model="uniform.elements[index].description"
              :label="`Descrição do elemento ${padWithZeros(index + 1)}`"
              hint="Descrição do elemento, para caso o usuário tenha dúvidas.'"
              persistent-hint
              outlined
            />
          </div>

          <v-btn color="secondary" class="my-5" @click="addElement()">
            <v-icon class="mr-1">mdi-plus</v-icon> Adicionar elemento
          </v-btn>
          <v-divider class="mt-1" />

          <div v-if="image && previewSVG && uniform.elements.length > 0 && uniform.elements[0].class" class="mt-4">
            <h2>Exemplo do uniforme modificado por um usuário:</h2>
            <h3>Se esta imagem estiver igual a original, verifique as Classes informadas.</h3>

            <inline-svg
              :key="uniform.elements.length"
              :src="previewSVG"
              :transformSource="transformSVG"
              class="svg-preview mt-4"
            />
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="createUniform()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  components: {
    ImageUploader: () => import('../../../components/ImageUploader.vue'),
    InlineSvg
  },
  data () {
    return {
      saving: false,
      valid: true,
      uniform: {
        name: '',
        imageType: undefined,
        elements: []
      },
      previewSVG: null,
      image: null,
      rules: {
        required: value => !!value || 'Este campo é obrigatório.'
      }
    }
  },
  computed: {
    createUniformModal: {
      get () {
        return this.$store.state.createUniformModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createUniformModal',
          value: val
        })
      }
    }
  },
  watch: {
    createUniformModal (val) {
      if (!val) {
        this.uniform = {
          name: '',
          imageType: '',
          elements: []
        }
        this.image = null
        this.previewSVG = null
      }
    }
  },
  methods: {
    createUniform () {
      if (this.$refs.form.validate()) {
        if (!this.uniform.imageType) {
          this.$toast.error('É necessário enviar uma imagem do uniforme.')
          return
        }

        if (this.uniform.imageType !== 'image/svg+xml') {
          this.$toast.error('A imagem deve ser um SVG.')
          return
        }

        if (this.uniform.elements.length === 0) {
          this.$toast.error('Informe pelo menos um elemento editável.')
          return
        }

        this.saving = true
        this.$http.post('/uniforms', this.uniform)
          .then((res) => {
            const { data } = res
            if (data.putUrl) {
              this.uploadImage(data.putUrl, this.image, this.success, this.error)
            } else {
              this.success()
            }
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      }
    },
    success () {
      this.saving = false
      this.createUniformModal = false
      this.$emit('created')
      this.$toast.success('Cadastro realizado com sucesso.')
    },
    error () {
      this.saving = false
      this.createUniformModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    addElement () {
      this.uniform.elements.push({
        class: '',
        name: '',
        description: ''
      })
    },
    removeElement (index) {
      this.uniform.elements.splice(index, 1)
    },
    handleImage (file) {
      if (file.type !== 'image/svg+xml') {
        this.$toast.warning('A imagem deve ser um SVG.')
      }
      this.uniform.imageType = file.type
      this.image = file

      var reader = new FileReader()
      var _this = this
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        _this.previewSVG = e.target.result
      }
    },
    handleRemoveImage () {
      this.uniform.imageType = undefined
      this.image = null
    },
    transformSVG (svg) {
      return this.transformUniformSVG(svg, this.uniform.elements)
    }
  }
}
</script>

<style scoped>
.element-box {
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
  padding: 40px;
  position: relative;
}

.delete-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 5;
}

.delete-btn:hover {
  transform: scale(1.1);
}

.svg-preview {
  width: 200px;
  height: 200px;
}
</style>
